<template>
  <Layout>
    <div
      class="slider-area slider-style-1 height-850 bg_image media-img"
      :style="{
        'background-image': `url(${require(`@/assets/images/gallery.png`)})`,
        opacity: `1`,
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner pt--80 text-center">
              <div class="react-image mb--20" data-aos="fade-up">
                <!-- <img
                    src="../assets/images/demo/badge-vue-js.png"
                    alt="卓能科技"
                  /> -->
              </div>
              <!-- <div>
                  <h3
                    class="rn-sub-badge"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <span class="theme-gradient"
                      >
                      产品案例
                      </span
                    >
                  </h3>
                </div> -->
              <h1
                class="title display-one"
                data-aos="fade-up"
                data-aos-delay="150"
                style="color: black"
              >
                <!-- 以我们的服务赢得客户的信任
                <br/>
  用我们的产品为客户创造价值 -->
              </h1>
              <div
                class="button-group"
                data-aos="fade-up"
                data-aos-delay="200"
                @click="scrollToElement('demo')"
                style="margin-top: 300px; cursor: pointer"
              >
                <!-- <a class="btn-default btn-large round btn-icon" href="#demo" -->
                <a class="btn-default btn-large round btn-icon"
                  >查看更多
                  <Icon name="arrow-down" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Our Demo -->
    <div
      class="rn-demo-area rn-section-gap"
      id="demo"
      style="padding-top: 100px"
    >
      <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
        <div class="row">
          <div class="col-lg-12">
            <!-- <SectionTitle text-align="center" subtitle="项目案例" /> -->
            <h3 style="text-align: center">项目案例</h3>
            <!--subtitle="All Demo Here" description="We create a business and consulting Vue.js template with 50+ elements features. <br /> 19+ demo pages, faster loading and well documentated code." -->
          </div>
        </div>
        <div class="row row--15">
          <div
            class="col-lg-4 col-md-6 col-12 mt--40"
            v-for="(page, index) in gallery"
            :key="index"
            data-aos="fade-up"
            :data-aos-delay="100 + index"
            @click="toDetail(index)"
          >
            <div style="justify-content: center; align-items: center">
              <!-- <router-link :to="page.url"> -->
              <div style="display: flex">
                <img
                  style="
                    width: 400px;
                    height: 250px;
                    border-radius: 10px;
                    margin: 0 auto;
                  "
                  :src="page.icon"
                  alt="Corporate images"
                />
                <!-- <img class="image-light" :src="page.imageLight" alt="无图片" style="height: 500px;"/> -->

                <!-- <span v-if="page.badge" class="label-badge">{{ page.badge }}</span> -->
              </div>
              <!-- </router-link> -->
              <h5 style="margin-top: 20px; text-align: left; margin-left: 12%">
                <!-- <router-link :to="page.url">{{ page.title }}</router-link> -->
                {{ page.caseName }}
                <Icon
                  style="float: right; margin-right: 13%"
                  name="arrow-right"
                />
              </h5>
              <div
                style="
                  font-size: 14px;
                  margin: 0 15%;
                  text-indent: 3ch;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ page.caseContent[0].content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Our Demo -->
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Breadcrumb from "../components/elements/breadcrumb/Breadcrumb";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import Gallery from "../components/elements/gallery/Gallery";
import Icon from "../components/icon/Icon";

import { caseListAll } from "@/api/index";

export default {
  name: "GalleryPage",
  components: { Gallery, SectionTitle, Breadcrumb, Layout, Icon },
  data() {
    return {
      galleryData: [
        {
          id: 1,
          thumb: require(`@/assets/images/portfolio/portfolio-01.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-01.jpg`),
          type: "image",
        },
        {
          id: 2,
          thumb: require(`@/assets/images/portfolio/portfolio-02.jpg`),
          src: "https://www.youtube.com/watch?v=d0tU18Ybcvk",
          type: "video",
        },
        {
          id: 3,
          thumb: require(`@/assets/images/portfolio/portfolio-03.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-03.jpg`),
          type: "image",
        },
        {
          id: 4,
          thumb: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          type: "image",
        },
        {
          id: 5,
          thumb: require(`@/assets/images/portfolio/portfolio-05.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-05.jpg`),
          type: "image",
        },
        {
          id: 6,
          thumb: require(`@/assets/images/portfolio/portfolio-06.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-06.jpg`),
          type: "image",
        },
      ],
      galleryData3: [
        {
          id: 1,
          thumb: require(`@/assets/images/portfolio/portfolio-01.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-01.jpg`),
          type: "image",
        },
        {
          id: 2,
          thumb: require(`@/assets/images/portfolio/portfolio-02.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-02.jpg`),
          type: "video",
        },
        {
          id: 3,
          thumb: require(`@/assets/images/portfolio/portfolio-03.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-03.jpg`),
          type: "image",
        },
        {
          id: 4,
          thumb: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          type: "image",
        },
        {
          id: 5,
          thumb: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          type: "image",
        },
        {
          id: 6,
          thumb: require(`@/assets/images/portfolio/portfolio-05.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-05.jpg`),
          type: "image",
        },
        {
          id: 7,
          thumb: require(`@/assets/images/portfolio/portfolio-06.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-06.jpg`),
          type: "image",
        },
        {
          id: 8,
          thumb: require(`@/assets/images/portfolio/portfolio-01.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-01.jpg`),
          type: "image",
        },
      ],

      gallery: [],

      galleryData01: [],
      galleryData02: [],
      galleryData03: [],
      galleryData04: [],
    };
  },
  mounted() {
    caseListAll().then((response) => {
      console.log(response);
      this.gallery = response.data;
    });
  },

  methods: {
    getGalleryData01(images) {
      var galleryData = [];
      if (images != "" || images.length != null) {
        for (let index = 0; index < images.length; index++) {
          galleryData.push({
            id: index + 1,
            thumb: images[index].image,
            src: images[index].image,
            type: "image",
          });
        }
      }

      return galleryData;
    },
    toDetail(index) {
      this.$router.push({ path: "/galleryDetail", query: { index: index } });
    },
    scrollToElement(elementId) {
      const el = document.getElementById(elementId);
      if (el) {
        window.scrollTo({
          top: el.offsetTop,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped>

@media (min-width: 768px) {
  /* PC样式 */
  .media-img {
    /* display: none; */

  }
}
@media (max-width: 767px) {
  /* 移动端样式 */
  .media-img {
    display: none;
  }
}
</style>
