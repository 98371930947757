<template>
  <Layout>
    <div style="margin-top: 50px;">
      <!-- Start Elements Area  -->
      <div class="" v-if="gallery[galleryIndex].caseContent[0]">
        <div class="container">
          <div class="">
            <div class="col-lg-12 mb--20">
              <h3
                class=""
                data-aos="fade-up"
                data-aos-delay="280"
                style="text-align: center"
              >
                <!-- {{ gallery[galleryIndex].caseName }} -->
                {{ gallery[galleryIndex].caseName }}
              </h3>
              <h5 style="text-align: left; text-indent: 2.5ch">
                {{ gallery[galleryIndex].caseContent[0].name }}
              </h5>
              <!-- <SectionTitle
                  text-align="center"
                  :subtitle="gallery[galleryIndex].caseContent[0].name"
                /> -->
              <div
                class=""
                data-aos="fade-up"
                data-aos-delay="280"
                style="
                  text-indent: 3ch;
                  line-height: 40px;
                  font-size: 17px;
                  color: black;
                "
              >
                {{ gallery[galleryIndex].caseContent[0].content }}
              </div>
            </div>
          </div>
          <Gallery
            :gallery-data="
              getGalleryData01(gallery[galleryIndex].caseContent[0].images)
            "
            column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
            v-show="
              gallery[galleryIndex].caseContent[0].images[0].image === '' || !gallery[galleryIndex].caseContent[0].images[0].image
                ? false
                : true
            "
          />
        </div>
      </div>
      <!-- End Elements Area  -->

      <!-- Start Elements Area  -->
      <div class="" v-if="gallery[galleryIndex].caseContent[1]" style="margin-top: -24px;">
        <div class="container">
          <div class="">
            <div class="col-lg-12 mb--20">
              <!-- <SectionTitle
                  text-align="center"
                  :subtitle="gallery[galleryIndex].caseContent[1].name"
                /> -->
              <h5 style="text-align: left; text-indent: 2.5ch">
                {{ gallery[galleryIndex].caseContent[1].name }}
              </h5>
              <div
                class=""
                data-aos="fade-up"
                data-aos-delay="280"
                style="
                  text-indent: 3ch;
                  line-height: 40px;
                  font-size: 17px;
                  color: black;
                "
              >
                {{ gallery[galleryIndex].caseContent[1].content }}
              </div>
            </div>
          </div>
          <Gallery
            :gallery-data="
              getGalleryData01(gallery[galleryIndex].caseContent[1].images)
            "
            column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
            v-show="
              gallery[galleryIndex].caseContent[1].images[0].image === '' || !gallery[galleryIndex].caseContent[1].images[0].image
                ? false
                : true
            "
          />
        </div>
      </div>
      <!-- End Elements Area  -->

      <!-- Start Elements Area  -->
      <div class="" v-if="gallery[galleryIndex].caseContent[2]" style="margin-top: -24px;">
        <div class="container">
          <div class="">
            <div class="col-lg-12 mb--20">
              <h4 style="text-align: center">
                {{ gallery[galleryIndex].caseContent[2].name }}
              </h4>
              <!-- <SectionTitle
                  text-align="center"
                  :subtitle="gallery[galleryIndex].caseContent[2].name"
                /> -->
              <div
                class=""
                data-aos="fade-up"
                data-aos-delay="280"
                style="
                  text-indent: 3ch;
                  line-height: 40px;
                  font-size: 17px;
                  color: black;
                "
              >
                {{ gallery[galleryIndex].caseContent[2].content }}
              </div>
            </div>
          </div>
          <Gallery
            :gallery-data="
              getGalleryData01(gallery[galleryIndex].caseContent[2].images)
            "
            column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30"
            v-show="
              gallery[galleryIndex].caseContent[2].images[0].image === '' || !gallery[galleryIndex].caseContent[2].images[0].image
                ? false
                : true
            "
          />
        </div>
      </div>
      <!-- End Elements Area  -->

      <!-- Start Elements Area  -->
      <div class="" v-if="gallery[galleryIndex].caseContent[3]" style="margin-top: -24px;">
        <div class="container">
          <div class="">
            <div class="col-lg-12 mb--20">
              <!-- <SectionTitle
                text-align="center"
                :subtitle="gallery[galleryIndex].caseContent[3].name"
              /> -->
              <h5 style="text-align: left; text-indent: 2.5ch">
                {{ gallery[galleryIndex].caseContent[3].name }}
              </h5>
              <div
                class=""
                data-aos="fade-up"
                data-aos-delay="280"
                style="
                  text-indent: 3ch;
                  line-height: 40px;
                  font-size: 17px;
                  color: black;
                "
              >
                {{ gallery[galleryIndex].caseContent[3].content }}
              </div>
            </div>
          </div>
          <Gallery
            :gallery-data="
              getGalleryData01(gallery[galleryIndex].caseContent[3].images)
            "
            column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30"
            v-show="
              gallery[galleryIndex].caseContent[3].images[0].image === '' || !gallery[galleryIndex].caseContent[3].images[0].image
                ? false
                : true
            "
          />
        </div>
      </div>
      <!-- End Elements Area  -->

      <!-- Start Elements Area  -->
      <div class="" v-if="gallery[galleryIndex].caseContent[4]" style="margin-top: -24px;">
        <div class="container">
          <div class="">
            <div class="col-lg-12 mb--20">
              <!-- <SectionTitle
                text-align="center"
                :subtitle="gallery[galleryIndex].caseContent[4].name"
              /> -->
              <h5 style="text-align: left; text-indent: 2.5ch">
                {{ gallery[galleryIndex].caseContent[4].name }}
              </h5>
              <div
                class=""
                data-aos="fade-up"
                data-aos-delay="280"
                style="
                  text-indent: 3ch;
                  line-height: 40px;
                  font-size: 17px;
                  color: black;
                "
              >
                {{ gallery[galleryIndex].caseContent[4].content }}
              </div>
            </div>
          </div>
          <Gallery
            :gallery-data="
              getGalleryData01(gallery[galleryIndex].caseContent[4].images)
            "
            column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30"
            v-show="
              gallery[galleryIndex].caseContent[4].images[0].image === '' || !gallery[galleryIndex].caseContent[4].images[0].image
                ? false
                : true
            "
          />
        </div>
      </div>
      <!-- End Elements Area  -->
    </div>
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Breadcrumb from "../components/elements/breadcrumb/Breadcrumb";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import Gallery from "../components/elements/gallery/Gallery";

import { caseListAll } from "@/api/index";

export default {
  name: "GalleryPage",
  components: { Gallery, SectionTitle, Breadcrumb, Layout },
  data() {
    return {
      gallery: [],

      galleryIndex: null,
    };
  },
  mounted() {
    console.log(this.$route.query.index);

    this.galleryIndex = this.$route.query.index;

    caseListAll().then((response) => {
      console.log(response);
      this.gallery = response.data;
    });
  },

  methods: {
    getGalleryData01(images) {
      var galleryData = [];
      if (images != "" || images.length != null) {
        for (let index = 0; index < images.length; index++) {
          galleryData.push({
            id: index + 1,
            thumb: images[index].image,
            src: images[index].image,
            type: "image",
          });
        }
      }

      return galleryData;
    },
    toDetail(index) {
      this.$router.push({ path: "/detail", query: { id: id } });
    },
  },
};
</script>
