var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticStyle:{"margin-top":"50px"}},[(_vm.gallery[_vm.galleryIndex].caseContent[0])?_c('div',{},[_c('div',{staticClass:"container"},[_c('div',{},[_c('div',{staticClass:"col-lg-12 mb--20"},[_c('h3',{staticStyle:{"text-align":"center"},attrs:{"data-aos":"fade-up","data-aos-delay":"280"}},[_vm._v(" "+_vm._s(_vm.gallery[_vm.galleryIndex].caseName)+" ")]),_c('h5',{staticStyle:{"text-align":"left","text-indent":"2.5ch"}},[_vm._v(" "+_vm._s(_vm.gallery[_vm.galleryIndex].caseContent[0].name)+" ")]),_c('div',{staticStyle:{"text-indent":"3ch","line-height":"40px","font-size":"17px","color":"black"},attrs:{"data-aos":"fade-up","data-aos-delay":"280"}},[_vm._v(" "+_vm._s(_vm.gallery[_vm.galleryIndex].caseContent[0].content)+" ")])])]),_c('Gallery',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.gallery[_vm.galleryIndex].caseContent[0].images[0].image === '' || !_vm.gallery[_vm.galleryIndex].caseContent[0].images[0].image
              ? false
              : true
          ),expression:"\n            gallery[galleryIndex].caseContent[0].images[0].image === '' || !gallery[galleryIndex].caseContent[0].images[0].image\n              ? false\n              : true\n          "}],attrs:{"gallery-data":_vm.getGalleryData01(_vm.gallery[_vm.galleryIndex].caseContent[0].images),"column":"col-lg-4 col-md-6 col-sm-6 col-12 mt--30"}})],1)]):_vm._e(),(_vm.gallery[_vm.galleryIndex].caseContent[1])?_c('div',{staticStyle:{"margin-top":"-24px"}},[_c('div',{staticClass:"container"},[_c('div',{},[_c('div',{staticClass:"col-lg-12 mb--20"},[_c('h5',{staticStyle:{"text-align":"left","text-indent":"2.5ch"}},[_vm._v(" "+_vm._s(_vm.gallery[_vm.galleryIndex].caseContent[1].name)+" ")]),_c('div',{staticStyle:{"text-indent":"3ch","line-height":"40px","font-size":"17px","color":"black"},attrs:{"data-aos":"fade-up","data-aos-delay":"280"}},[_vm._v(" "+_vm._s(_vm.gallery[_vm.galleryIndex].caseContent[1].content)+" ")])])]),_c('Gallery',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.gallery[_vm.galleryIndex].caseContent[1].images[0].image === '' || !_vm.gallery[_vm.galleryIndex].caseContent[1].images[0].image
              ? false
              : true
          ),expression:"\n            gallery[galleryIndex].caseContent[1].images[0].image === '' || !gallery[galleryIndex].caseContent[1].images[0].image\n              ? false\n              : true\n          "}],attrs:{"gallery-data":_vm.getGalleryData01(_vm.gallery[_vm.galleryIndex].caseContent[1].images),"column":"col-lg-4 col-md-6 col-sm-6 col-12 mt--30"}})],1)]):_vm._e(),(_vm.gallery[_vm.galleryIndex].caseContent[2])?_c('div',{staticStyle:{"margin-top":"-24px"}},[_c('div',{staticClass:"container"},[_c('div',{},[_c('div',{staticClass:"col-lg-12 mb--20"},[_c('h4',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.gallery[_vm.galleryIndex].caseContent[2].name)+" ")]),_c('div',{staticStyle:{"text-indent":"3ch","line-height":"40px","font-size":"17px","color":"black"},attrs:{"data-aos":"fade-up","data-aos-delay":"280"}},[_vm._v(" "+_vm._s(_vm.gallery[_vm.galleryIndex].caseContent[2].content)+" ")])])]),_c('Gallery',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.gallery[_vm.galleryIndex].caseContent[2].images[0].image === '' || !_vm.gallery[_vm.galleryIndex].caseContent[2].images[0].image
              ? false
              : true
          ),expression:"\n            gallery[galleryIndex].caseContent[2].images[0].image === '' || !gallery[galleryIndex].caseContent[2].images[0].image\n              ? false\n              : true\n          "}],attrs:{"gallery-data":_vm.getGalleryData01(_vm.gallery[_vm.galleryIndex].caseContent[2].images),"column":"col-lg-3 col-md-6 col-sm-6 col-12 mt--30"}})],1)]):_vm._e(),(_vm.gallery[_vm.galleryIndex].caseContent[3])?_c('div',{staticStyle:{"margin-top":"-24px"}},[_c('div',{staticClass:"container"},[_c('div',{},[_c('div',{staticClass:"col-lg-12 mb--20"},[_c('h5',{staticStyle:{"text-align":"left","text-indent":"2.5ch"}},[_vm._v(" "+_vm._s(_vm.gallery[_vm.galleryIndex].caseContent[3].name)+" ")]),_c('div',{staticStyle:{"text-indent":"3ch","line-height":"40px","font-size":"17px","color":"black"},attrs:{"data-aos":"fade-up","data-aos-delay":"280"}},[_vm._v(" "+_vm._s(_vm.gallery[_vm.galleryIndex].caseContent[3].content)+" ")])])]),_c('Gallery',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.gallery[_vm.galleryIndex].caseContent[3].images[0].image === '' || !_vm.gallery[_vm.galleryIndex].caseContent[3].images[0].image
              ? false
              : true
          ),expression:"\n            gallery[galleryIndex].caseContent[3].images[0].image === '' || !gallery[galleryIndex].caseContent[3].images[0].image\n              ? false\n              : true\n          "}],attrs:{"gallery-data":_vm.getGalleryData01(_vm.gallery[_vm.galleryIndex].caseContent[3].images),"column":"col-lg-3 col-md-6 col-sm-6 col-12 mt--30"}})],1)]):_vm._e(),(_vm.gallery[_vm.galleryIndex].caseContent[4])?_c('div',{staticStyle:{"margin-top":"-24px"}},[_c('div',{staticClass:"container"},[_c('div',{},[_c('div',{staticClass:"col-lg-12 mb--20"},[_c('h5',{staticStyle:{"text-align":"left","text-indent":"2.5ch"}},[_vm._v(" "+_vm._s(_vm.gallery[_vm.galleryIndex].caseContent[4].name)+" ")]),_c('div',{staticStyle:{"text-indent":"3ch","line-height":"40px","font-size":"17px","color":"black"},attrs:{"data-aos":"fade-up","data-aos-delay":"280"}},[_vm._v(" "+_vm._s(_vm.gallery[_vm.galleryIndex].caseContent[4].content)+" ")])])]),_c('Gallery',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.gallery[_vm.galleryIndex].caseContent[4].images[0].image === '' || !_vm.gallery[_vm.galleryIndex].caseContent[4].images[0].image
              ? false
              : true
          ),expression:"\n            gallery[galleryIndex].caseContent[4].images[0].image === '' || !gallery[galleryIndex].caseContent[4].images[0].image\n              ? false\n              : true\n          "}],attrs:{"gallery-data":_vm.getGalleryData01(_vm.gallery[_vm.galleryIndex].caseContent[4].images),"column":"col-lg-3 col-md-6 col-sm-6 col-12 mt--30"}})],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }